


























import { v4 as uuid } from "uuid";
import moment from "moment";
import { defineComponent } from "@vue/composition-api";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";

interface IHeader {
  width: number;
  value: string;
  isLink?: boolean;
}

interface IResultData {
  icon: string;
  status: string;
  number: string;
  date: string;
  id: string;
}

export default defineComponent({
  name: "EadInventoryTable",
  props: {
    element: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    openInventory(id: string) {
      if (!id) {
        return;
      }
      this.$router.push({
        path: "/inventory/case",
        query: { id, isOpenModal: "1" },
      });
    },
  },
  computed: {
    headers(): IHeader[] {
      return [
        { width: 30, value: "icon" },
        { width: 100, value: "status" },
        { width: 200, value: "number", isLink: true },
        { width: 100, value: "date" },
      ];
    },
    resultData(): IResultData[] {
      return [
        {
          icon: `${environmentVariables.BASE_URL}assets/images/ead/inv-base.svg`,
          status: this.element?.inventory?.type,
          number: this.element?.inventory?.id,
          date: this.element?.inventory?.date ? moment(this.element?.inventory?.date).format("YYYY-MM-DD") : "",
          id: uuid(),
        },
      ];
    },
  },
});
